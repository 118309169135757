
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.chipsItem {
  padding: 0 8px !important;
  cursor: pointer;
  @include themify($themes) {
    background-color: themed('gray20');
    border-color: themed('gray20');
  }

  &:hover {
    @include themify($themes) {
      color: themed('primaryColor');
      background-color: themed('primary60Color');
      border-color: themed('primary60Color');
    }
  }

  @include btn-icon-hover();

  &.disabled:hover {
    @include themify($themes) {
      color: themed('textColor') !important;
      background-color: themed('gray20');
      border-color: themed('gray20');
    }

    & :global .fill {
      @include themify($themes) {
        fill: themed('gray35') !important;
      }
    }
  }

  & span {
    max-width: 160px;
  }

  &.dateRange > span {
    max-width: 232px;
  }

  &.editable {
    cursor: pointer;
  }

  &.isSigned {
    :global .stroke {
      stroke-width: 2px;
      stroke: #22C933 !important;
    }
  }

  &.toSign {
    cursor: pointer;
    transition: .3s;
    box-sizing: border-box;
    @include themify($themes) {
      border-color: themed('primaryColor') !important;
      background-color: themed('primaryColor') !important;
      color: themed('mainBg') !important;
      :global .fill {
        fill: themed('mainBg') !important;
      }
    }
    position: relative;

    span {
      text-align: center;
      min-width: 24px;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('mainBg') !important;
        color: themed('textColor') !important;
        :global .fill {
          fill: themed('primaryColor') !important;
        }
      }
    }
  }

  &.price {
    flex-direction: row-reverse;

    img {
      margin-right: 8px;
    }
  }

  &.readOnly {
    @include themify($themes) {
      border-color: themed('cardHeader') !important;
      background-color: themed('cardHeader') !important;
      color: themed('mainBg') !important;
    }
  }

  &.rejected {
    cursor: default;
    box-sizing: border-box;
    @include themify($themes) {
      color: themed('textColor') !important;
      background-color: themed('gray20') !important;
      :global .fill {
        fill: themed('red1') !important;
      }
    }

    span {
      min-width: 24px;
    }
  }
}

.wrap {
  position: relative;
  &:empty {
    display: none;
  }
}

.calendarPopup {
  position: absolute;
  top: 0;
  left: 16px;
  z-index: 2;
}

.loader {
  position: absolute;
  width: calc(100% - 16px);
  background-color: inherit;
}


